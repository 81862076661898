import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useSpring, animated } from "react-spring"
import styled from "styled-components"
import { Location } from "@reach/router"
import whiteLogo from "../images/beauty-gems-logo_white.png"
import LeadHeroImg from "../images/lead_hero_image.jpg"
import Nav from "./navigation"

const HeaderWrapper = styled.header`
  /*background: #ef017c;*/
  padding: 20px 0;
  position: relative;
  z-index: 100;
  background: ${props => (props.location !== "/" ? "#d53f8c;" : "inherit")};
  background: ${props => (props.location !== "/" ? "#ef017c" : "#ef017c")};

  .inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const MainLogo = styled.img`
  display: block;
  margin-bottom: 0;
  width: 100px;
`

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  color: #fff;
  background: #ffdce0;
  display: flex;
  align-items: center;

  .inner-container {
    display: flex;
    @media (max-width: 840px) {
      flex-direction: column;
    }
  }

  .hero-content_left {
    flex: 1;
    max-width: calc(100%);
    padding: 120px 0;

    @media (max-width: 840px) {
      padding: 40px 0;
      text-align: center;
    }
  }

  .hero-content_right {
    display: flex;
    align-items: flex-end;

    img {
      max-width: 400px;
      display: block;
      margin-left: 50px;
      @media (max-width: 840px) {
        margin: 0 auto;
        max-width: 200px;
      }
    }
  }

  h1 {
    color: #333;
    color: #ef017c;
  }
  p {
    color: #ef017c;
  }
`

const HomepageLeadImage = styled.img`
  width: 100%;
  margin-top: 100px;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
`

const MenuIcon = styled.div`
  display: none;
  cursor: pointer;
  border: 2px solid #fff;
  color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
  font-weight: 600;
  @media (max-width: 750px) {
    display: block;
  }
`

const Header = ({ siteTitle, headerImage, location }) => {
  const [isToggled, setToggle] = useState(false)

  const fadeInLeft = useSpring({
    from: {
      opacity: 0,
      transform: `translateX(-500px)`,
    },
    to: {
      opacity: 1,
      transform: `translateX(0)`,
    },
  })

  const fadeInRight = useSpring({
    from: {
      opacity: 0,
      transform: `translateX(500px)`,
    },
    to: {
      opacity: 1,
      transform: `translateX(0)`,
    },
  })

  return (
    <div>
      <HeaderWrapper location={location}>
        <div className="inner-container">
          <Link to="/">
            <MainLogo src={whiteLogo} alt="Beauty Gems Logo" />
          </Link>
          <Nav setToggle={setToggle} toggleState={isToggled} />
          <MenuIcon onClick={() => setToggle(!isToggled)}>Menu</MenuIcon>
        </div>
      </HeaderWrapper>
      {location && location === "/" ? (
        <HeroContent>
          <div className="inner-container">
            <animated.div style={fadeInLeft} className="hero-content_left">
              <h1>Mobile Beautician in Newark</h1>
              <p>
                Operating in and around the Newark area, including Balderton,
                Claypole, Farndon, Muskham, Elston, Collingham, Winthorpe,
                Coddington and other surrounding villages.
              </p>
              <div className="global-btn-wrapper">
                <Link className="global-btn global-btn_blue" to="/contact">
                  Book Now
                </Link>
              </div>
            </animated.div>
            <animated.div style={fadeInRight} className="hero-content_right">
              <img src={LeadHeroImg} />
            </animated.div>
          </div>
        </HeroContent>
      ) : (
        ""
      )}
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
