import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useSpring, animated } from "react-spring"
import styled from "styled-components"
import Icon from "../utilities/icons"
import paymentCardLogos from "../images/accepted_cards_logos.jpg"

const FooterContainer = styled.footer`
  background: #f1f5f8;
  padding: 0 0 20px 0;

  .inner-container {
    border-top: 1px solid #d9d9d9;
    padding-top: 20px;
    font-size: 14px;
  }

  .footer-top {
    padding-bottom:20px;
    border-bottom:1px dashed #d9d9d9;
    margin-bottom:20px;
    text-align:center;
    img {
      display:block;
      margin:0 auto;
      width:100%;
      max-width: 400px;
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    @media (max-width: 675px) {
      flex-direction: column;
      text-align: center;
    }
  }


  .footer-center {
  }

  .footer-right {
    a {
      color: #ff7459;
      &:hover {
        color: #ff4126;
      }
    }
  }
`
const ToTopBtn = styled.div`
  position: fixed;
  right: 30px;
  bottom: 60px;
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-bottom: 2px solid #ef017c;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 5000;
  @media (max-width: 750px) {
    right: 20px;
  }
`

const Footer = ({}) => {
  useEffect(() => {
    const vpHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    )
    const toTopBtn = document.querySelector(".toTopBtn")

    setInterval(function() {
      var scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop

      if (scrollTop > vpHeight) {
        toTopBtn.style.display = "flex"
      } else {
        toTopBtn.style.display = "none"
      }
    }, 1000)
  })

  const goToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  return (
    <FooterContainer>
      <div className="inner-container">
      <div className="footer-top">
          <div style={{padding:10, backgroundColor:"#f5f5f5", borderRadius:5}}>
            <h5>Card Payments Now Accepted</h5>
            <img src={paymentCardLogos} alt="Accepted Cards - Visa, Mastercard, Apple Pay, Google Pay" />
          </div>
        </div>
        <div class='footer-bottom'>
          <div className="footer-center">
            © {new Date().getFullYear()} | Beauty Gems
          </div>
          <div className="footer-right">
            <span>
              Web Design &amp; Development by -{" "}
              <a href="http://www.projodesign.co.uk">
                Projo Design | Web Design Newark
              </a>
            </span>
          </div>
        </div>
 
      </div>
      <ToTopBtn onClick={() => goToTop()} className="toTopBtn">
        <Icon name="up_arrow" color="#ef017c" width="16" height="16" />
      </ToTopBtn>
    </FooterContainer>
  )
}

export default Footer
