import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import Icon from "../utilities/icons"

const Nav = styled.nav`
  padding: 0px 0;

  .nav-title-mobile {
    display: none;
  }

  a {
    color: #fff;
    display: inline-block;
    margin-left: 18px;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    border-bottom: 1px solid transparent;
    &:first-of-type {
      margin-left: 0;
    }
    &:hover {
      border-bottom: 1px solid #fff;
    }
  }

  .special-nav-item {
    border: 1px solid #fff;
    padding: 5px 15px;
    &:hover {
      background: #d60063;
    }
  }

  .nav-close-btn {
    display: none;
    cursor: pointer;
  }

  @media (max-width: 750px) {
    display: ${props => (props.toggleState ? "block" : "none")};

    background: rgba(255, 255, 255, 0.99);
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    padding: 40px 40px;
    border-radius: 10px;
    z-index: 9999;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);

    .nav-title-mobile {
      display: block;
      color: #ef017c;
      font-weight: 400;
      margin-bottom: 20px;
    }

    a {
      display: block;
      color: #333;
      margin-left: 0;
      padding: 8px 0;
      text-decoration: none;
      font-weight: 600;

      &:hover {
        background: none;
        color: #666;
      }

      .special-nav-item {
        border: none;
        padding: inherit;
        &:hover {
          background: none;
          border-bottom: 0;
        }
      }
    }
    .nav-close-btn {
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
`

class Navigation extends React.Component {
  render() {
    return (
      <Nav toggleState={this.props.toggleState}>
        <h4 className="nav-title-mobile">Navigation</h4>

        <Link to="/">
          <span>Home</span>
        </Link>
        <Link to="/about">
          <span>About</span>
        </Link>
        <Link to="/treatments">
          <span>Treatments</span>
        </Link>
        <Link to="/contact">
          <span>Contact</span>
        </Link>
        <Link to="/contact">
          <span className="special-nav-item">Book Now</span>
        </Link>
        <div
          onClick={() => this.props.setToggle(false)}
          className="nav-close-btn"
        >
          <Icon name="close" color="#ef017c" />
        </div>
      </Nav>
    )
  }
}

export default Navigation
